import React from 'react'

import useVerifyRole from '../../../hooks/verifyRole'
import Hero from '../../../components/Hero'
import Section from '../../../components/Section'
import Button from '../../../components/Button'
import SEO from '../../../components/SEO'
import { Link } from 'gatsby'

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])

  if (loading || !hasRole) {
    return null
  }

  return (
    <>
      <SEO title="Gavekort" />
      <Hero title={<><Link to="/admin">Admin</Link> &gt; Gavekort</>} type={['center', 'small']} />

      <Section size="small">
        <div
          style={{ textAlign: 'center', minHeight: 'calc(100vh - 48.3rem)' }}
        >
          <Button to="/admin/gavekort/lag-faktura" primary arrow spaceless>
            Lag faktura
          </Button>
          <br /><Button to="/admin/gavekort/lag-flerbruk" primary arrow spaceless>
            Lag flerbruksgavekort
          </Button>
          <br />
          <Button to="/admin/gavekort/sok" primary arrow spaceless>
            Søk etter gavekort
          </Button>
        </div>
      </Section>
    </>
  )
}
